import React from 'react'
import ErrorBoundary from '@components/error/boundary'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ButtonCta from '@components/atoms/button-cta'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import CardFeaturedStory from '@components/card-featured-story/index'
import SliderNew from '@components/slider-new'
import useStyles from './relevant-articles.styles'

const RelevantArticles = (props) => {
  const {
    headline, ctaText, link, storyCollection,
  } = props
  if (!storyCollection || storyCollection.total === 0) return null

  const headlineText = headline || 'Relevant Stories'
  const styles = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <ErrorBoundary>
      <Box className={styles.root}>
        <Container className={styles.container}>
          <Box className={styles.headerLine}>
            <Typography variant="h4" variantMapping={{ h4: 'h2' }} className={styles.headline}>
              {headlineText}
            </Typography>
            {!isMobile && link && (
              <ButtonCta
                href={link}
                variant="text"
                color="primary"
                arrow
                className={styles.cta}
              >
                {ctaText}
              </ButtonCta>
            )}
          </Box>

          {storyCollection?.items && storyCollection.items.length > 0 && (
            <SliderNew className={styles.slider}>
              {storyCollection.items.map(item => (
                <CardFeaturedStory key={item.sys.id} {...item} />
              ))}
            </SliderNew>
          )}

          {isMobile && link && (
            <Box className={styles.mCta}>
              <ButtonCta
                href={link}
                variant="text"
                color="primary"
                arrow
              >
                {ctaText}
              </ButtonCta>
            </Box>
          )}
        </Container>
      </Box>
    </ErrorBoundary>
  )
}

RelevantArticles.propTypes = {
  headline: PropTypes.string,
  ctaText: PropTypes.string,
  link: PropTypes.string,
  categoryList: PropTypes.shape({
    items: PropTypes.array,
  }),
  storyCollection: PropTypes.shape({
    items: PropTypes.array,
    total: PropTypes.number,
    __typename: PropTypes.string,
  }),
}

RelevantArticles.defaultProps = {}

export default RelevantArticles
