import React from 'react'
import ErrorBoundary from '@components/error/boundary'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'
import SliderNew from '@components/slider-new'
import { centerDesktopConfig } from '@components/slider-new/utils'
import useStyles from './partner-logos.styles'
import LogoCard from './logo-card'

const PartnerLogos = (props) => {
  const {
    headline, logoCollection,
  } = props
  const styles = useStyles()

  return (
    <ErrorBoundary>
      <Box className={styles.partnerLogosRoot}>
        <Box className={styles.container}>
          <Box className={styles.headerLine}>
            <Typography variant="h4" variantMapping={{ h4: 'h3' }} className={styles.headline}>
              {headline || 'Official Partners'}
            </Typography>
          </Box>
          {logoCollection?.items && logoCollection.items.length > 0 && (
            <SliderNew centerSlider config={centerDesktopConfig}>
              {logoCollection.items.map(item => (
                <LogoCard key={item.sys.id} {...item} />
              ))}
            </SliderNew>
          )}
        </Box>
      </Box>
    </ErrorBoundary>
  )
}

PartnerLogos.propTypes = {
  headline: PropTypes.string,
  logoCollection: PropTypes.shape({
    items: PropTypes.array,
    total: PropTypes.number,
    __typename: PropTypes.string,
  }),
}

PartnerLogos.defaultProps = {}

export default PartnerLogos
