import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '3.75rem',
    paddingBottom: '3.75rem',
    overflow: 'hidden',
  },
  headline: {
    color: theme.palette.secondary.light,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  headerLine: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '1.25rem',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  slider: {
    padding: '30px 0px',
  },
  cta: {
    padding: 0,
  },
  mCta: {
    marginTop: '1.75rem',
    paddingBottom: '1.75rem',
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default useStyles
