import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { Image } from '@components/atoms'
import useStyles from './partner-logos.styles'

const LogoCard = (props) => {
  const { className, url, title } = props
  const styles = useStyles()

  const altText = `Official partner logo for ${title.split(' ')[0]}`

  return (
    <Box className={`${styles.cardRoot} ${className}`}>
      <Box className={styles.imageContainer}>
        {url && <Image className={styles.image} src={`${url}`} alt={altText} />}
      </Box>
    </Box>
  )
}

LogoCard.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
  title: PropTypes.string,
}

LogoCard.defaultProps = {
  className: '',
}

export default LogoCard
